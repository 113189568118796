<div class="text-right pr-8">
    <ul class="inline-flex flex-col items-end gap-y-4">
        <li *ngFor="let item of menu">
            <a [routerLink]="[item.route]" routerLinkActive="text-primary active">
                {{ item.name | translate }}
            </a>
        </li>

        <li class="pt-8">
            <a href="#" (click)="onProfileClick($event)" translate>btn.profile</a>
        </li>

        <li *ngIf="(userSwitchEnabled$ | async) === true">
            <a href="#" (click)="onUserSwitchClick($event)" translate>btn.switch-user</a>
        </li>

        <li *ngIf="(userSwitchEnabled$ | async) === false">
            <a href="#" (click)="onLogoutClick($event)" translate>btn.logout</a>
        </li>
    </ul>
</div>
